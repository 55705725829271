input[type="radio"] {
    display: none;
}

/* label {
    color: white;
    z-index: 10;
    cursor: pointer;
} */


h4 {
    margin: 0;
    font-weight: 500;
}

.black-font {
    color: black !important;
}

.main-container {
    /* margin-top: 20px; */
    display: inline-block;
    vertical-align: middle;
    width: 50%;
    height: 28px;
    border-radius: 100px;
    background-color: #4d4d4d;
    position: absolute;
    top: 20%;
    left: 45%;
}

.switch {
    height: 20px;
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    border-radius: 100px;
    position: absolute;
    left: 1px;
    top: 4px;
    transition: ease-in;
}

/*---------------- LEFT ------------------- */
.switch.left-to-center {
    animation-name: leftToCenter;
    animation-duration: 0.5s;
}

.switch.left-to-right {
    animation-name: leftToRight;
    animation-duration: 0.5s;
}

.switch.left-position {
    left: 3px;
    /* background-image: url("error.svg"); */
}

.main-container.left-position {
    background-color: #198754;
}

.left-label {
    position: absolute;
    color: white;
    z-index: 10;
    cursor: pointer;
}

.left-button+label {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 20px;
    width: 20px;
    border-radius: 100px;
    top: 4px;
    left: 3px;
}

.left-button:checked+label {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 20px;
    width: 20px;
    border-radius: 100px;
    top: 4px;
    left: 3px;
}

@keyframes leftToCenter {
    from {
        left: 3px;
    }

    to {
        left: 36px;
    }
}

@keyframes leftToRight {
    from {
        left: 3px;
    }

    to {
        left: 71px;
    }
}

/* -------------------- center ------------------ */
.switch.center-to-left {
    animation-name: centerToLeft;
    animation-duration: 0.5s;
}

.switch.center-to-right {
    animation-name: centerToRight;
    animation-duration: 0.5s;
}

.switch.center-position {
    left: 36px;
    /* background-image: url("warning.svg"); */
}


.main-container.center-position {
    background-color: #6c757d;
}

.center-label {
    position: absolute;
    left: 36px;
}

.center-button+label {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 17px;
    width: 17px;
    border-radius: 100px;
    top: 6px;
    /* right: -13px; */
    padding-left: 4px;

}

.center-button:checked+label {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 17px;
    width: 17px;
    border-radius: 100px;
    top: 6px;
    /* right: -13px; */
    padding-left: 4px;
}

@keyframes centerToLeft {
    from {
        left: 36px;
    }

    to {
        left: 3px;
    }
}

@keyframes centerToRight {
    from {
        left: 36px;
    }

    to {
        right: 35px;
    }
}

/* ------------------- RIGHT ------------------- */
.switch.right-to-left {
    animation-name: rightToLeft;
    animation-duration: 0.5s;
}

.switch.right-to-center {
    animation-name: rightToCenter;
    animation-duration: 0.5s;
}

.switch.right-position {
    left: 71px;
    /* background-image: url("success.svg"); */
}

.main-container.right-position {
    background-color: #DC3545;
}

.right-label {
    position: absolute;
    right: 1px;
    color: white;
    z-index: 10;
    cursor: pointer;
}

.right-button+label {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 24px;
    width: 24px;
    border-radius: 100px;
    top: 2px;
    right: 2px;
}

.right-button:checked+label {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 24px;
    width: 24px;
    border-radius: 100px;
    top: 2px;
    right: 2px;
}

@keyframes rightToLeft {
    from {
        left: 71px;
    }

    to {
        left: 3px;
    }
}

@keyframes rightToCenter {
    from {
        left: 71px;
    }

    to {
        right: 36px;
    }
}